import React from 'react'
import {HTMLContent} from './Content'
import ShareLinks from './ShareLinks'
import Helmet from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
//import PropTypes from 'prop-types'

class DocumentPageTemplate extends React.Component {
  constructor(props) {
    super()
    this.languages = props.languages
    
    this.state = {
      langSet: 0,
    }
    this.description = props.description
    this.thumbnail = props.thumbnail
    this.title = props.title
    this.url = props.url
    this.readingTime = props.readingTime
    this.date = props.date

    this.path = props.path


  }

  setLang(val) {
    this.setState({ langSet: val})
  }

  getNavigation = () => {
    if(this.languages.length > 1 ) {
      return (
        < >
          {this.languages.map( (node, index) => (
        <li key={index} onClick={() => ( this.setLang(index) )} className={index === this.state.langSet ? "is-active" : null}><a>{node.language}</a></li>
          ))}
        </ >
      )
    } else {
      return null;
    }
  }


  getContent = () => {
    return (
      <HTMLContent className="content" content={this.languages[this.state.langSet].content.childMarkdownRemark.html} />
    )
  }

  getTitle = () => {
    return (
      <h1 className="is-title is-size-1">{this.languages[this.state.langSet].title}</h1>
    )
  }

  render() {



  return (
    < >
    <Helmet>
      <meta property="og:url"                content={this.url+this.path} />
      <meta property="og:type"               content="article" />
      <meta property="og:title"              content={this.title} />
      <meta property="og:description"        content={this.description} />
      <meta property="og:image"              content={this.url+this.thumbnail.childImageSharp.fluid.src} />
    </Helmet>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-7 is-offset-1">
            <this.getTitle />
            <p><small className="accent-color">Posted on {this.date} | {this.readingTime}</small></p>
            <div class="dropdown is-hoverable" style={{marginBottom: '0'}}>
              <div class="dropdown-trigger">
                <button class="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu4">
                  <span>Share</span>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                <div class="dropdown-content">
                  <ShareLinks slug={this.path} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tabs is-centered">
          <ul>
            <this.getNavigation />
          </ul>
        </div>


        <div className="columns">
          <div className="column is-7 is-offset-1">
            <this.getContent />
          </div>
        </div>
      </div>
    </section>
    </ >
  )
  }
} 


export default DocumentPageTemplate
