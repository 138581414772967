import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TranslatedShareablePageTemplate from '../components/TranslatedShareablePageTemplate'

const TranslatedShareablePage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout>
      <TranslatedShareablePageTemplate
        languages={page.frontmatter.languages}
        path={page.frontmatter.path}
        url={data.site.siteMetadata.siteUrl}
        date={page.frontmatter.date}
        readingTime={page.frontmatter.readingTime}
        thumbnail={page.frontmatter.thumbnail}
        description={page.frontmatter.description}
        title={page.frontmatter.title}
      />
    </Layout>
  )
}

TranslatedShareablePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TranslatedShareablePage

export const translatedShareablePageQuery = graphql`
  query TranslatedShareablePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        path
        description
        date(formatString: "MMMM DD, YYYY")
        readingTime
        title
        thumbnail {
          childImageSharp {
            fluid (maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        languages {
          language
          title
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
